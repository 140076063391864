/*===============================
    Breakpoints
================================= */

export const breakpoints = {
  extra: "1600px",
  laptop: "1200px",
  lg: "991px",
  md: "768px",
  sm: "576px",
  largeMobile: "480px"
}
