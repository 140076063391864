export const rainbowPalette = [
  // rgb
  "var(--color-secondary)",
  "#12d8df",
  "#59C98D",
  "#6F41F6",
  "#357FF6",
  "#f767b4",
  "#fbe973"
]

export const getRainbowColorSequence = (i) => rainbowPalette[i % rainbowPalette.length]
