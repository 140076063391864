import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { client } from "../../prismic"
import { useRouteMatch } from "react-router-dom"
import { RichText } from "prismic-reactjs"
import Loading from "../../components/Loading"
import Breadcrumb from "../../components/Breadcrumb"
import { useTranslation } from "react-i18next"

/*
 * Person Person query
 */
const personQuery = gql`
  query person($uid: String) {
    allPersons(uid: $uid) {
      # data
      edges {
        node {
          _meta {
            id
            uid
            type
          }
          name
          designation
          heroimage
          description
        }
      }
    }
  }
`

const queryPersonByUid = (uid) => {
  const queryOptions = {
    query: personQuery,
    variables: { uid }
  }

  return client.query(queryOptions)
}

const PersonSingle = () => {
  const [loading, setLoading] = useState(false)
  const [person, setPerson] = useState(null)
  const [notFound, toggleNotFound] = useState(false)
  const { params } = useRouteMatch()
  const id = params["id"]
  const [t] = useTranslation()

  // Get the person person document from Prismic
  useEffect(() => {
    const _ = async () => {
      setLoading(true)

      const prismicContent = await queryPersonByUid(id)
      const doc = prismicContent.data.allPersons.edges.length > 0 ? prismicContent.data.allPersons.edges[0].node : null

      if (doc) {
        console.log(doc)
        setPerson(doc)
      } else {
        toggleNotFound(true)
      }
      setLoading(false)
    }

    _().then()
  }, [id])

  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image"
        data-black-overlay="5"
        style={{ backgroundImage: `url(${person?.heroimage?.url})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="person-single-page-title text-center pt--100">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <h2 className="title theme-gradient">{person?.name[0]?.text}</h2>
                    <br />
                    <span className="theme-gradient description">{person?.designation[0]?.text}</span>
                    <Breadcrumb
                      breadcrumb={[
                        { text: t("Home"), to: "/" },
                        { text: t("People"), to: "/people" },
                        { text: person?.name[0]?.text }
                      ]}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      <div className="rn-person-details pt--110 pb--70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <div className="about-wrapper">
                        <div className="container">
                          <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                              <div className="thumbnail">
                                <img className="w-100" src={person?.heroimage?.thumbnail.url} alt="About Images" />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                              <div className="about-inner inner">
                                <div className="section-title">
                                  <h2 className="title">{person?.name[0]?.text}</h2>
                                  <h4 className="subtitle">{person?.designation[0]?.text}</h4>
                                  <RichText render={person?.description} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PersonSingle
