import React from "react"
import { useTranslation } from "react-i18next"

import L1 from "../assets/images/brand/nsw-government.png"
import L2 from "../assets/images/brand/vic-government.png"
import L3 from "../assets/images/brand/usyd.png"
import L4 from "../assets/images/brand/yes-education.png"
import L5 from "../assets/images/brand/westpac.png"
import L6 from "../assets/images/brand/iag.png"
import L7 from "../assets/images/brand/nike.png"
import L8 from "../assets/images/brand/ford.png"
import L9 from "../assets/images/brand/behringer.png"
import L10 from "../assets/images/brand/711.jpg"
import L11 from "../assets/images/brand/mcdonalds.png"
import L12 from "../assets/images/brand/vans.png"
import L13 from "../assets/images/brand/tanned.png"
import L14 from "../assets/images/brand/nrl.png"

import styled from "styled-components"
import { breakpoints } from "../assets/scss/default/variables"

const brands = [L1, L2, L3, L4, L5, L6, L7, L8, L9, L10, L11, L12, L13, L14]

const BrandImg = styled.img`
  //height: 200px;
  @media (max-width: ${breakpoints.lg}) {
    max-width: 100%;
    height: unset;
  }
`

const PartnerBrands = () => {
  const [t] = useTranslation()

  return (
    <div>
      <div className="col-lg-12">
        <div className="section-title text-center">
          <span className="subtitle">{t("Testimonials")}</span>
          <h2 className="title">{t("Our Clients")}</h2>
        </div>
      </div>
      <ul className="brand-style-2">
        {brands.map((b, i) => (
          <li key={i}>
            <BrandImg src={b} alt="b" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PartnerBrands
