import React from "react"
import { FaBusinessTime, FaReact, FaSass } from "react-icons/fa"
import { FiActivity, FiCast, FiMap, FiSend, FiSmartphone } from "react-icons/all"
import Slider from "../components/Slider"
import HomeBg from "../assets/images/slider/home/1-blur.jpg"
import { useTranslation } from "react-i18next"
import { Section } from "../components/styled/layouts"
import AboutBlock from "../assets/images/home-about.jpg"
import { Link } from "react-router-dom"
import styled from "styled-components"
import PartnerBrands from "../components/PartnerBrands"
import { getRainbowColorSequence } from "../assets/color"

const SkewedCard = styled.div`
  transform: matrix(1, -0.15, 0, 1, 0, 0);
  position: absolute !important;
  height: 100%;
  width: 100%;
  z-index: -1 !important;
`

const featureList = [
  {
    Icon: FaReact,
    title: "Yaso",
    subtitle: "Currency Exchange Management with multi-store, access control, double-entry ledgers, rate management",
    to: "/products/yaso"
  },
  {
    Icon: FiSmartphone,
    title: "Irvon",
    subtitle: "Student application tracking software",
    to: "/products/irvon"
  },
  {
    Icon: FaSass,
    title: "Foxsplit",
    subtitle: "Open-source tree-shaking software for AST clean up of React-SCSS components.",
    to: "/products/foxsplit"
  },
  {
    Icon: FaBusinessTime,
    title: "Ryre",
    subtitle: "Australia e-commerce solution that integrates with BigCommerce, Stripe and Braintree",
    to: "/products/ryre"
  }
]

export const OurProducts = ({ className = "" }: { className? }) => {
  const [t] = useTranslation()
  return (
    <Section>
      <div className={`service-area ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center pb--30">
                <span className="subtitle">{t("home:Our products")}</span>
                <h2 className="title">{t("home:Software as a Service")}</h2>
              </div>
            </div>
          </div>
          <div className="row service-main-wrapper">
            {/* Start Single Feature  */}
            {featureList.map(({ Icon, subtitle, title, to }, i) => (
              <Link className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12" key={i} to={to}>
                <div className="h-100 position-relative">
                  <SkewedCard className="service shadow service__style--2" />
                  <div className="icon" style={{ color: getRainbowColorSequence(i) }}>
                    <Icon size={50} />
                  </div>
                  <div className="content" style={{ padding: 10 }}>
                    <h3 className="title">{title}</h3>
                    <p className="subtitle">{subtitle}</p>
                  </div>
                </div>
              </Link>
            ))}
            {/* End Single Feature  */}
          </div>
        </div>
      </div>
    </Section>
  )
}

const AboutInner = styled.div`
  @media #{$md-layout} {
    padding-top: 40px;
  }
  @media #{$sm-layout} {
    padding-top: 40px;
  }

  .icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: var(--color-primary);
    border-radius: 100%;
    text-align: center;
    color: #fff;
    line-height: 58px;
    font-size: 27px;
    margin-bottom: 17px;
    padding-right: 3px;

    svg {
      stroke-width: 1;
    }
  }
  .section-title {
    h2 {
      &.title {
        line-height: 1.3;
        margin-bottom: 35px;
      }
    }
    p {
      &.description {
        font-weight: 300;
        color: var(--color-dark);
        a {
          color: rgba(29, 29, 36, 1);
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }
`

const About = () => {
  const { t } = useTranslation()

  const title = t("Just what exactly do we do?")
  const descriptions = [
    // 1
    `Technologies are important for businesses, and our team is here to assist. 
      From data modelling for business intelligence, to app and web development, we have you covered.`,
    // 2
    `Based in Sydney, the team of Roller provide friendly services to our customers. 
     Our focus is to make sure that we as a company provide the highest 
     quality service and better products.`
  ]

  return (
    <Section>
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="thumbnail">
              <img className="w-100" src={AboutBlock} alt="About Images" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <AboutInner>
              <div className="section-title">
                <div className="icon">
                  <FiSend />
                </div>
                <h2 className="title">{title}</h2>
                {descriptions.map((p) => (
                  <p className="description" key={p}>
                    {p}
                  </p>
                ))}
                <div className="purchase-btn">
                  <Link className="btn-transparent" to="/contact">
                    Get in contact
                  </Link>
                </div>
              </div>
            </AboutInner>
          </div>
        </div>
      </div>
    </Section>
  )
}

const Home = () => {
  const [t, i18n] = useTranslation()

  const title = t("home:sliderTitle")
  const titleTop = t("home:sliderTitleTop")
  const ServiceList = [
    {
      icon: <FiActivity />,
      title: t("home:Modern technologies"),
      description: t("home:We use latest technologies to deliver industrial grade products")
    },
    {
      icon: <FiCast />,
      title: t("home:Comprehensive team"),
      description: t("home:Team of experts from different areas including corporate finance, logistics and management")
    },
    {
      icon: <FiMap />,
      title: t("home:Result driven"),
      description: t("home:We care for our deliverables and clients, that means we deliver timely updates with quality")
    }
  ]

  return (
    <>
      <Slider title={title} titleTop={titleTop} showServices backgroundImage={HomeBg}>
        {/* Start Service Area */}
        <div className="service-white">
          <div className="row">
            {ServiceList.map((val, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <div className="service service__style--1">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h4 className="title">{val.title}</h4>
                    <p>{val.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Service Area */}
      </Slider>

      {/* End Banner Area  */}

      {/* Start Preview Main Wrapper */}
      <Section className={" bg_color--5"}>
        {/* Start Feature Area  */}
        <About />
      </Section>
      <Section>
        <div className="container">
          {/* Out Clients */}
          <PartnerBrands />
        </div>
        <OurProducts />
      </Section>
    </>
  )
}

export default Home
