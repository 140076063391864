import React, { lazy, Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/all"
import { FullPageLoading } from "./components/Loading"
import Router from "./Router"
import ScrollToTopHelper from "./components/helpers/ScrollToTopHelper"

// lazy components
const Footer = lazy(() => import("./components/Footer"))
const Header = lazy(() => import("./components/Header"))

const App = () => (
  <div className="App">
    <Suspense fallback={<FullPageLoading />}>
      <BrowserRouter>
        <Header />

        <ScrollToTopHelper />
        <Router />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </BrowserRouter>
    </Suspense>
  </div>
)

export default App
