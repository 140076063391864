import React, { lazy } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Home from "./pages/Home"
import PersonSingle from "./pages/people/PersonSingle"
const PersonList = lazy(() => import("./pages/people/PersonList"))
const ServicesSwitch = lazy(() => import("./pages/services"))
const ProductsSwitch = lazy(() => import("./pages/products"))
const Contact = lazy(() => import("./pages/Contact"))
const BlogSingle = lazy(() => import("./pages/blog/BlogSingle"))
const BlogList = lazy(() => import("./pages/blog/BlogList"))

const Router = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/products" component={ProductsSwitch} />

    <Route path="/services" component={ServicesSwitch} />

    <Route path="/people/:id" component={PersonSingle} />
    <Route path="/people" component={PersonList} />
    <Route path="/contact" component={Contact} />
    <Route path="/blog/:id" component={BlogSingle} />
    <Route path="/blog" component={BlogList} />
    <Redirect from="*" to="/" />
  </Switch>
)
export default Router
