import React from "react"
import styled from "styled-components"

const SingleSlide = styled.div``

const RotatedBackground = styled.div`
  height: 200px;
  width: 100%;
  position: relative;
  z-index: -1;
  //filter: blur(40px);
  //overflow: hidden;
  background-color: white;
  top: -100px;
`

const RotatedBackgroundInner = styled.div`
  transform-origin: -1500px 100%;
  transform: matrix(1, -0.15, 0, 1, 0, 0);
  width: 100%;
  min-height: 900px;
  position: absolute;
  background-repeat: repeat;
`

const Slider = ({
  title,
  description,
  titleTop,
  minHeight = 900,
  backgroundImage,
  children
}: {
  title?: string
  description?: string
  titleTop?: string
  showServices?: boolean
  minHeight?: number
  backgroundImage?: string
  children?: React.ReactNode
}) => {
  return (
    <div className="pv-slider-area slider-wrapper">
      <div className="slider-activation" style={{ minHeight }}>
        {/* Start Single Slide */}
        <RotatedBackground style={{ position: "relative" }}>
          <RotatedBackgroundInner className="bg_image" style={{ backgroundImage: `url(${backgroundImage})` }} />
        </RotatedBackground>

        <SingleSlide className="slide slide-style-1 slider-fixed--height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-left">
                  <span className="theme-gradient font-500">{titleTop}</span>
                  <br />
                  <h1 className="title theme-gradient">{title}</h1>
                  <p className="description">{description}</p>
                </div>
              </div>
            </div>
            {children}
          </div>
        </SingleSlide>
        {/* End Single Slide */}
      </div>
    </div>
  )
}
export default Slider
