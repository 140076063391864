import React from "react"
import styled from "styled-components"

const Loading = ({ horizontalCenter = true }: { horizontalCenter?: boolean }) => (
  <div className={horizontalCenter ? "d-flex justify-content-center w-100 mt-3 mb-3" : ""}>
    <span className="spinner-grow" role="status">
      <span className="sr-only">Loading...</span>
    </span>
  </div>
)

export const LoadingMinimal = ({ style }) => (
  <span style={style} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
)

const PageBlock = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FullPageLoading = () => (
  <PageBlock>
    <Loading />
  </PageBlock>
)

export default Loading
